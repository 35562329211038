<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--models-label-add-edit">
      <div class="view-header">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'templatesLabel' }"
            round
            grow
            icon="arrow-left"
          />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <Input id="designation" label="Désignation" required />
            <div class="form grid">
              <Input id="marge_haut" label="Marge haute" type="number"
                     :options="{step: 0.1}" required input-after="cm" />
              <div class="tmp-col">
                <Input id="marge_bas" label="Marge basse" type="number"
                       :options="{step: 0.1}" required input-after="cm"/>
              </div>
              <div class="tmp-col">
                <Input id="marge_droite" label="Marge droite"  type="number"
                       :options="{step: 0.1}" required input-after="cm"/>
              </div>
              <Input id="marge_gauche" label="Marge gauche"  type="number"
                     :options="{step: 0.1}" required input-after="cm"/>
              <Input id="page_largeur" label="Largeur de la page"  type="number"
                     :options="{step: 0.1}" required input-after="cm"/>
              <Input id="page_hauteur" label="Hauteur de la page"  type="number"
                     :options="{step: 0.1}" required input-after="cm"/>
              <Input id="nb_etiquettes_x"
                     label="Nombre de colonnes d'étiquettes par page"  type="number" required
              />
              <Input id="nb_etiquettes_y"
                     label="Nombre de lignes d'étiquettes par page"  type="number" required
              />
              <Input id="marge_etiquette_y"
                     label="Marge horizontale entre les étiquettes"  type="number"
                     :options="{step: 0.1}" required
                     input-after="cm"
              />
              <Input id="marge_etiquette_x"
                     label="Marge verticale entre les étiquettes"  type="number"
                     :options="{step: 0.1}" required
                     input-after="cm"
              />
            </div>
          </Container>
        </Section>
        <Section v-if="!hasModel">
          <Container>
            <RepeatableInputs
              v-if="repeatables.zones && repeatables.zones.default"
              label="Zones"
              id="zones"
              :errors="errors"
              :default-selection="repeatables.zones.default"
              :items="repeatables.zones.items"
              :n-cols="4"
              hasIndex
              :required="true"
            />
            <RepeatableInputs
              v-else-if="repeatables.zones"
              label="Zones"
              id="zones"
              :errors="errors"
              :items="repeatables.zones.items"
              :n-cols="4"
              hasIndex
              :required="true"
            />
          </Container>
        </Section>
        <Section v-else>
          <Container>

            <Input v-if="schema.zones_hidden === 'true'" type="hidden" id="zones_hidden"/>
            <Alert class="margin-bottom" content="Un ou plusieurs modèles d'étiquettes sont associés à ce gabarit, les zones ne sont pas modifiables." type="warning"/>
            <KeyValue label="Zones">
                <RepeatableValues
                  :labels="['Position X', 'Position Y', 'Largeur', 'Hauteur']"
                  :values="repeatables.zones.readOnly"
                  :nCols="4"
                  hasIndex
                />
            </KeyValue>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'templatesLabel' }" text="Annuler" />
          <Btn
            v-if="this.method === 'put'"
            v-show="helperService.userHasPermission('dictionariesetiquettegabarit_remove') && this.method === 'put'"
            hollow
            @click="modalDeleteGabarit = true"
            text="Supprimer"
          />

          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>

    <Modal
      v-if="$route.params.id && templateLabel"
      title="Supprimer le gabarit&nbsp;?"
      :active="modalDeleteGabarit"
      :data="templateLabel"
      @modal-close="modalDeleteGabarit = false"
    >
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer le gabarit
          <b>{{ data.designation }}</b
          >?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalDeleteGabarit = false" />
        <Btn
          text="Supprimer"
          @click="deleteGabarit()"
          icon="trash-alt"
          color="primary"
        />
      </template>
    </Modal>
  </form>
  <!-- Tool menu -->
  <ToolMenu
    v-if="$route.params.id"
  >
    <MenuItem
      v-show="helperService.userHasPermission('dictionariesetiquettegabarit_show')"
      :item-data="{
        label: 'Consulter',
        iconName: 'visibility',
        route: {
          name: 'templateLabel',
          params: {
            id: $route.params.id
          }
        }
      }"
    />
    <MenuItem
      v-show="helperService.userHasPermission('dictionariesetiquettegabarit_remove')"
      :item-data="{
        label: 'Supprimer',
        iconName: 'trash-alt',
      }"
      @click="modalDeleteGabarit = true"
    />
  </ToolMenu>
</template>

<script>
import Alert from '@/components/base/Alert.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolMenu from '@/components/layout/ToolMenu.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'

export default {
  name: 'TemplatesLabelAddEditView',
  components: {
    Alert,
    RepeatableInputs,
    Input,
    Btn,
    Section,
    Container,
    Modal,
    ToolMenu,
    MenuItem,
    KeyValue,
    RepeatableValues,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().required().nullable(),
      marge_haut: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      marge_bas: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      marge_droite: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      marge_gauche: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      page_largeur: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      page_hauteur: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      nb_etiquettes_x: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      nb_etiquettes_y: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      marge_etiquette_x: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      marge_etiquette_y: this.yup.number().typeError('Le champ est obligatoire').required().nullable(),
      zones_hidden: this.yup.boolean().nullable(),
      zones: this.yup.array().of(
        this.yup.object().shape({
          x: this.yup.number().integer().required().nullable(),
          y: this.yup.number().integer().required().nullable(),
          largeur: this.yup.number().integer().required().nullable(),
          hauteur: this.yup.number().integer().required().nullable(),
        }),
      ).when(['zones_hidden'], (zonesHidden, schema) => (zonesHidden === 'true' ? schema.nullable() : schema.required('Vous devez créer au moins une zone')))
        .nullable(),
    })

    const { errors, isSubmitting, currentFormValues } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (this.hasModel) {
        this.currentFormValues.zones = this.repeatables.zones.default
      }
      this.handleValues(values)
    })

    return {
      schema: {},
      hasModel: false,
      modalDeleteGabarit: false,
      templateLabel: {},
      templateLabelId: this.$route.params.id ?? null,
      data: {},
      entity: [],
      method: 'post',
      apiEndpoint: 'dictionnaire/etiquette/gabarit',
      repeatables: {
        zones: {
          items: {
            group: [
              {
                label: 'Position X',
                name: 'x',
                type: 'number',
                required: true,
                options: {
                  step: 1, min: 0, max: 100, 'input-after': '%',
                },
              },
              {
                label: 'Position Y',
                name: 'y',
                type: 'number',
                required: true,
                options: {
                  step: 1, min: 0, max: 100, 'input-after': '%',
                },
              },
              {
                label: 'Largeur',
                name: 'largeur',
                type: 'number',
                required: true,
                options: {
                  step: 1, min: 0, max: 100, 'input-after': '%',
                },
              },
              {
                label: 'Hauteur',
                name: 'hauteur',
                type: 'number',
                required: true,
                options: {
                  step: 1, min: 0, max: 100, 'input-after': '%',
                },
              },
            ],
          },
        },
      },
      onSubmit,
      errors,
      isSubmitting,
      currentFormValues,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    if (this.templateLabelId) {
      this.emitter.emit('open-loader')
      this.checkAssociatedModel()
      this.method = 'put'
      this.apiEndpoint = `dictionnaire/etiquette/gabarit/${this.templateLabelId}`
      this.emitter.emit('close-loader')
    }
  },

  methods: {
    checkAssociatedModel() {
      this.fetchService.get(`etiquette/modele?filters=gabarit.id:eq(${this.templateLabelId})&limit=0`).then((response) => {
        this.hasModel = response.data.length > 0
        this.getTemplateLabel()
      })
    },
    getTemplateLabel() {
      this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.templateLabelId}`).then((response) => {
        this.templateLabel = response.data

        this.schema = this.formService.populateShema(this.templateLabel)

        this.schema.zones_hidden = this.hasModel

        this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.templateLabel.id}/zone`).then((zones) => {
          this.repeatables.zones.default = zones.data.map((zone) => ({
            id: this.formatNumber(zone.id),
            x: this.formatNumber(zone.x),
            y: this.formatNumber(zone.y),
            largeur: this.formatNumber(zone.largeur),
            hauteur: this.formatNumber(zone.hauteur),
          }))
          this.repeatables.zones.readOnly = zones.data.map((zone) => ({
            x: `${this.formatNumber(zone.x)} %`,
            y: `${this.formatNumber(zone.y)} %`,
            largeur: `${this.formatNumber(zone.largeur)} %`,
            hauteur: `${this.formatNumber(zone.hauteur)} %`,
          }))
        })

        // set form values
        this.formService.setFormValues(this.schema)
      })
    },

    async handleValues(values) {
      // prepare the data for API
      this.emitter.emit('open-loader')
      this.fetchService[this.method](this.apiEndpoint, {
        uid: this.helperService.generateUid(values.designation),
        designation: values.designation,
        marge_haut: this.formatNumber(values.marge_haut),
        marge_bas: this.formatNumber(values.marge_bas),
        marge_droite: this.formatNumber(values.marge_droite),
        marge_gauche: this.formatNumber(values.marge_gauche),
        page_largeur: this.formatNumber(values.page_largeur),
        page_hauteur: this.formatNumber(values.page_hauteur),
        nb_etiquettes_x: this.formatNumber(values.nb_etiquettes_x),
        nb_etiquettes_y: this.formatNumber(values.nb_etiquettes_y),
        marge_etiquette_x: this.formatNumber(values.marge_etiquette_x),
        marge_etiquette_y: this.formatNumber(values.marge_etiquette_y),
      }).then((template) => {
        this.emitter.emit('alert', {
          type: 'success',
          content: this.method === 'post' ? 'Le gabarit a bien été créé.' : 'Le gabarit a bien été mis à jour.',
        })

        const { id } = template.data

        const { zonesCreated, idsZonesDeleted, zonesUpdated } = this.getZonesToStore(
          values,
          this.repeatables.zones.default || [],
        )

        zonesCreated.forEach((zone) => {
          this.fetchService.post(`dictionnaire/etiquette/gabarit/${template.data.id}/zone`, {
            x: this.formatNumber(zone.x),
            y: this.formatNumber(zone.y),
            largeur: this.formatNumber(zone.largeur),
            hauteur: this.formatNumber(zone.hauteur),
          }).then(() => {
            this.getZones(id)
          })
          return this.data.zones
        })

        idsZonesDeleted.forEach((did) => {
          this.fetchService.delete(`dictionnaire/etiquette/gabarit/${template.data.id}/zone/${did}`).then(
            () => {
              this.getZones(id)
            },
          )
          return this.data.zones
        })

        zonesUpdated.forEach((zone) => {
          this.fetchService.put(`dictionnaire/etiquette/gabarit/${template.data.id}/zone/${zone.id}`, zone).then(
            () => {
              this.getZones(id)
            },
          )
          return this.data.zones
        })
        this.emitter.emit('list-refresh')
      })

      this.emitter.emit('close-loader')
      this.$router.push({ name: 'templatesLabel' })
    },

    formatNumber(value) {
      return parseFloat(value)
    },

    getZones(id) {
      this.fetchService.get(`dictionnaire/etiquette/gabarit/${id}/zone`).then((r) => {
        this.data.zones = r.data
      })
    },

    getZonesToStore(postParams, datas) {
      const zonesIdsFromDb = datas.map((zone) => zone?.id)

      const zonesUpdated = postParams.zones.filter((zone) => (
        zone.id ? zone : false
      ))

      const idsZonesUpdated = zonesUpdated.map((zone) => zone.id)

      const idsZonesDeleted = zonesIdsFromDb.filter((i) => !idsZonesUpdated.includes(i))

      const zonesCreated = postParams.zones.filter((zone) => (
        !(zone.id) ? zone : false
      ))

      return { zonesCreated, zonesUpdated, idsZonesDeleted }
    },

    deleteGabarit() {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`dictionnaire/etiquette/gabarit/${this.templateLabel.id}`)
        .then(
          (response) => {
            console.log(response)
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le gabarit a bien été supprimé.',
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
            this.$router.push({ name: 'templatesLabel' })
          },
          (error) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: error.message,
            })
            this.emitter.emit('close-loader')
          },
        )

      this.modalDeleteGabarit = false
    },
  },
}
</script>
