<template>
  <div class="view view--product">
    <div class="view-header">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          :to="{ name: 'templatesLabel' }"
          round
          grow
          icon="arrow-left"
        />
        <h1 class="page-title" v-html="`${data.designation}`"></h1>
      </div>
    </div>
    <div
      class="view-body"
    >
      <Section>
        <Container>
          <div class="form grid">
            <div class="tmp-col">
              <KeyValue label="Désignation" :value="data.designation"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge haute" :value="data.marge_haut + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge basse" :value="data.marge_bas + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge droite" :value="data.marge_droite + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge gauche" :value="data.marge_gauche + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Largeur de la page" :value="data.page_largeur + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Hauteur de la page" :value="data.page_hauteur + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Nombre de colonnes d'étiquettes par page"
                        :value="data.nb_etiquettes_x"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Nombre de lignes d'étiquettes par page"
                        :value="data.nb_etiquettes_y"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge horizontale entre les étiquettes"
                        :value="data.marge_etiquette_y + ' cm'"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Marge verticale entre les étiquettes"
                        :value="data.marge_etiquette_x + ' cm'"/>
            </div>
            <div class="col--fullwidth">
              <KeyValue label="Zones">
                <RepeatableValues
                  :labels="['Position X', 'Position Y', 'Largeur', 'Hauteur']"
                  :values="repeatables?.zones"
                  :nCols="4"
                  hasIndex
                />
              </KeyValue>
            </div>

          </div>

        </Container>
      </Section>

    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn
          v-show="helperService.userHasPermission('dictionariesetiquettegabarit_edit')"
          :to="{ name: 'templateLabelEdit', params: { id: id } }"
          class="action-btn"
          text="Modifier le gabarit"
          icon="create"
          color="primary"
        />
      </div>
    </div>
  </div>

</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'TemplateLabelView',

  components: {
    KeyValue,
    RepeatableValues,
    Btn,
    Section,
    Container,
  },

  data() {
    return {
      data: [],
      id: null,
      repeatables: {},

    }
  },

  async created() {
    this.id = Number(this.$route.params.id)
    this.getData()
  },

  unmounted() {
    this.data = []
    this.repeatables = {}
  },

  methods: {
    getData() {
      return this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.id}`, {}).then(
        (response) => {
          this.data = response.data
          this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.id}/zone`).then((r) => {
            this.data.zones = r.data
            this.repeatables = this.getRepeatablesToDisplay(r.data)
            console.log(this.repeatables)
          })
        },
        (responseError) => {
          console.log('fetchError', responseError)
        },
      )
    },

    getRepeatablesToDisplay(datas) {
      if (datas.length > 0) {
        this.repeatables.zones = datas
          .map((n) => {
            const data = {
              'Position X': `${n.x} %`,
              'Position Y': `${n.y} %`,
              Largeur: `${n.largeur} %`,
              Hauteur: `${n.hauteur} %`,
            }
            return data
          })
      }

      return this.repeatables
    },
  },
}
</script>
